import { IonButtons, IonContent, IonModal, IonToolbar } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { Form, StyledButton } from '../common/Modals';
import * as yup from 'yup';
import { ModalProps } from '../common/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatabaseContext, CardsContext } from '../App';
import { useContext, useEffect } from 'react';
import { useUser } from '../hooks/userHooks';
import moment from 'moment';

const MatchLogSchema = yup.object().shape({
    date: yup.date().default(() => new Date()).required(),
    startTime: yup.string().required(),
    endTime: yup.string().required().test('is-greater', 'End time must be later than start time', function(value) {
        const { startTime } = this.parent;
        return moment(value, 'HH:mm').isSameOrAfter(moment(startTime, 'HH:mm'));
    }),
    partner: yup.string().required(),
    result: yup.string().required(),
    notes: yup.string(),
})

interface CreateTrainingLogModalProps extends ModalProps {
    activeLog: any,
}

const CreateMatchLogModal: React.FC<CreateTrainingLogModalProps> = (props: CreateTrainingLogModalProps) => {
    const user = useUser();
    const userId: string = user.getUserNonAsync().userId || '';

    const { register, reset, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(MatchLogSchema),
    });

    const cards = useContext(CardsContext);
    const database = useContext(DatabaseContext);

    const onSubmit = (data: any) => {
        console.log(data);
        let tempData = data;
        tempData.date = new Date(data.date).toString();
        tempData.time = new Date(data.date).getTime();
        if (data.uid) {
            database.ref().child(userId).child('matches').child(data.uid).update(tempData, (error) => {
                console.log(error);
            }).then(() => {
                reset();
                cards.refreshMatches();
                props.dismiss();
            });
        } else {
            const newPostRef = database.ref(userId + '/' + 'matches').push();
            tempData.uid = newPostRef.key;
            newPostRef.set(tempData, (error) => {
                console.log(error);
            }).then(() => {
                reset();
                cards.refreshMatches();
                props.dismiss();
            });
        }
    }

    useEffect(() => {
        console.log(props.activeLog);
        setValue('date', moment(new Date(props.activeLog.date)).format('yyyy-MM-DD'));
        setValue('notes', props.activeLog.notes);
        setValue('startTime', props.activeLog.startTime);
        setValue('endTime', props.activeLog.endTime);
        setValue('partner', props.activeLog.partner);
        setValue('result', props.activeLog.result);
        setValue('uid', props.activeLog.uid);
    }, [props.activeLog]);

    return (
        <IonModal swipeToClose={false} showBackdrop={true} isOpen={props.modalStatus} onDidDismiss={() => { reset(); props.dismiss(); }}>
            <IonContent>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <h1>Add/Edit Match Log</h1>
                    <label>Session Date</label>
                    <input type="date" {...register("date")} />
                    {errors.date && <span>Date can't be empty</span>}
                    <label>Start Time</label>
                    <input type="time" {...register("startTime")} />
                    {errors.startTime && <span>Start time can't be empty</span>}
                    <label>End Time</label>
                    <input type="time" {...register("endTime")} />
                    {errors.endTime && (errors.endTime.type !== "is-greater") && <span>End time can't be empty</span>}
                    {errors.endTime && (errors.endTime.type == "is-greater") && <span>End time must be later than start time</span>}
                    <label>Result</label>
                    <select {...register("result")}>
                        <option value="win">Win</option>
                        <option value="draw">Draw</option>
                        <option value="loss">Loss</option>
                    </select>
                    {errors.result && <span>Result can't be empty</span>}
                    <label>Partner</label>
                    <input type="text" {...register("partner")} />
                    {errors.partner && <span>Partner can't be empty</span>}
                    <label>Notes</label>
                    <textarea {...register("notes")} rows={3} />
                </Form>
            </IonContent>
            <IonToolbar>
                <IonButtons slot="end">
                    <StyledButton onClick={() => {
                        reset();
                        props.dismiss();
                    }}>
                        Cancel
                    </StyledButton>
                </IonButtons>
                <IonButtons slot="end">
                    <StyledButton onClick={() => {
                        handleSubmit(onSubmit)();
                    }}>
                        Done
                    </StyledButton>
                </IonButtons>
            </IonToolbar>
        </IonModal>
    )
}

export default CreateMatchLogModal;
