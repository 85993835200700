import { IonButton, IonContent, IonPage, IonRefresher, IonRefresherContent } from '@ionic/react';
import { useState, useEffect, useContext } from 'react';
import { useUser } from '../hooks/userHooks';
import { RefresherEventDetail } from '@ionic/core';
import { styled } from "../theme/stitches.config";
import { AuthContext, CardsContext } from '../App';
import moment from 'moment';

interface User {
  userName?: string | null;
  userId?: string | null;
}

const LogoutButton = styled(IonButton, {
  // marginTop: '24px',
})

const About: React.FC = () => {
  const userName = useUser().getUserNonAsync().userName || '';

  const { logout } = useContext(AuthContext);
  const cards = useContext(CardsContext);

  const [trainingMinutes, setTrainingMinutes] = useState<Number>(0);
  const [matchMinutes, setMatchMinutes] = useState<Number>(0);

  const checkMatches = () => {
    let tempMinutes = 0;
    cards.rackets.matches.orderedArray.forEach(key => {
      tempMinutes = tempMinutes + moment(cards.rackets.matches.cards[key].endTime, 'HH:mm').diff(moment(cards.rackets.matches.cards[key].startTime, 'HH:mm'), 'minutes');
    })
    setMatchMinutes(tempMinutes);
  }

  const checkTraining = () => {
    let tempMinutes = 0;
    cards.rackets.training.orderedArray.forEach(key => {
      tempMinutes = tempMinutes + moment(cards.rackets.training.cards[key].endTime, 'HH:mm').diff(moment(cards.rackets.training.cards[key].startTime, 'HH:mm'), 'minutes');
    })
    setTrainingMinutes(tempMinutes);
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setTimeout(() => {
      // checkMatches();
      // checkTraining();
      event.detail.complete();
    }, 500);
  }

  useEffect(() => {
    checkMatches();
    checkTraining();
  }, [cards]);

  const StatContainer = styled('div', {
    display: 'flex',
    flexFlow: 'column wrap',
    padding: '48px 76px 0 76px',
    fontFamily: '$blSans',
    '& h1': {
      textAlign: 'center',
    },
    '& h2': {
      textAlign: 'center',
      fontSize: '14px',
      margin: 0,
    },
    '& p': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  });

  const LogoutContainer = styled('div', {
    padding: '24px 76px 0 76px',
    fontFamily: '$blSans',
    textAlign: 'center',
    '& h1': {
      textAlign: 'center',
    },
    '& h2': {
      textAlign: 'center',
      fontSize: '14px',
      margin: 0,
    },
    '& p': {
      marginTop: '24px !important',
      textAlign: 'center',
      fontSize: '12px',
      color: '#7E8999',
      margin: 0,
    },
  });

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent>
          </IonRefresherContent>
        </IonRefresher>
        <div className="container">
          <StatContainer>
            <h1>Your Stats</h1>
            <h2>{userName}</h2>
            <p><b>Rackets: </b>{cards.rackets.rackets.orderedArray.length ? cards.rackets.rackets.orderedArray.length : 0}</p>
            <p><b>Matches (All Time): </b>{cards.rackets.matches.orderedArray.length ? cards.rackets.matches.orderedArray.length : 0}</p>
            <p><b>Match Minutes (All Time):</b>{matchMinutes ? matchMinutes : 0}</p>
            <p><b>Training Sessions (All Time):</b>{cards.rackets.training.orderedArray.length ? cards.rackets.training.orderedArray.length : 0}</p>
            <p><b>Training Minutes (All Time):</b>{trainingMinutes ? trainingMinutes : 0}</p>
          </StatContainer>
          <LogoutContainer>
            <LogoutButton type="button" onClick={() => logout()}>Logout</LogoutButton>
            <p>Tennis Logger was developed with lots of love by MengBot. To get in touch, email us <a href="mailto:meng@bandlab.com">here</a>.</p>
          </LogoutContainer>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default About;
