import { IonItem, IonLabel } from '@ionic/react';
import { format } from 'date-fns';
import React from 'react';
import { styled } from '@stitches/react';
import moment from 'moment';

interface RecordCardProps {
    onClick(): void;
    log: any;
}

const TrainingNotes = styled('p', {
    marginBottom: '4px !important',
})

const RecordItem = styled(IonItem, {
    cursor: 'pointer',
    '& h4': {
        fontFamily: 'BandLab Sans',
        textTransform: 'uppercase',
        fontSize: '12px',
        letterSpacing: '1px',
    },
    '& .title': {
        fontFamily: 'BandLab Sans',
        fontSize: '14px',
        color: 'black !important',
        marginTop: '4px !important',
    },
    '& .subtitle': {
        fontSize: '12px',
        fontFamily: 'BandLab Sans',
        textTransform: 'uppercase',
    },
});

const RecordCard: React.FC<RecordCardProps> = (props: RecordCardProps) => {
    return (
        <RecordItem className="recordCard" onClick={() => props.onClick()}>
            <IonLabel>
                <h4>{format(new Date(props.log.time), 'MMMM d, yyyy')} - {props.log.startTime}</h4>
                <p className="ion-text-wrap title">{props.log.partner ? props.log.partner : 'N/A'}</p>
                {props.log.notes ? <TrainingNotes className="ion-text-wrap title" style={{ fontWeight: 300 }}>{props.log.notes} </TrainingNotes> : ''}
                <p className="subtitle">+{moment(props.log.endTime, 'HH:mm').diff(moment(props.log.startTime, 'HH:mm'), 'minutes')} Minutes{props.log.result ? ` - ${props.log.result}` : ''}</p>
            </IonLabel>
        </RecordItem>
    )
}

export default RecordCard;
