import { IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel } from "@ionic/react";
import { format } from 'date-fns';
import { useRef } from "react";
import { styled } from "@stitches/react";
import { Racket, StringJob } from "../common/types";

interface StringJobCellProps {
    id: string;
    stringJob: StringJob;
    // racket: Racket;
    editStringJob(stringJob: StringJob): void;
    deleteStringJob(key: string): void;
}

const StringJobItem = styled(IonItemSliding, {
    cursor: 'pointer',

    '& ion-item-option': {
        fontFamily: 'BandLab Sans',
        minWidth: '150px',
    },
    '& h4': {
        fontFamily: 'BandLab Sans',
        textTransform: 'uppercase',
        fontSize: '12px',
        letterSpacing: '1px',
    },
    '& .title': {
        fontFamily: 'BandLab Sans',
        marginTop: '4px',
        color: 'black !important',
    },
    '& .subtitle': {
        fontFamily: 'BandLab Sans',
        fontSize: '12px',
        textTransform: 'uppercase',
    },
});

const StringJobCell: React.FC<StringJobCellProps> = (props: StringJobCellProps) => {

    const ionItem = useRef<HTMLIonItemSlidingElement>(null);
    const {
        stringJob,
        // racket,
    } = props;

    return (
        <StringJobItem ref={ionItem}>
            {/* <IonItemOptions side="start">
                <IonItemOption onClick={() => {alert('editing string job');ionItem.current?.closeOpened();}}>Edit</IonItemOption>
            </IonItemOptions> */}
            <IonItem onClick={() => console.log('click')}>
                <IonLabel>
                    {/* {racket ? <h4>{racket.brand} {racket.name} (#{racket.number})</h4> : ''} */}
                    {stringJob.time ? <p className="subtitle">Date: {format(new Date(stringJob.time), 'dd/MM/yy')}</p> : ''}
                    {stringJob.tensionMains && stringJob.tensionCrosses ? <p className="subtitle">Tension (lbs): {stringJob.tensionMains} / {stringJob.tensionCrosses} </p> : ''}
                    {stringJob.stringMainsName ? <p className="subtitle">Mains / Crosses: {stringJob.stringMainsName} / {stringJob.stringCrossesName ? stringJob.stringCrossesName : stringJob.stringMainsName} </p> : ''}
                    {stringJob.stringedBy ? <p className="subtitle">By: {stringJob.stringedBy}</p> : ''}
                </IonLabel>
            </IonItem>
            <IonItemOptions side="end">
                <IonItemOption color="danger" onClick={() => props.deleteStringJob(props.id)}>Delete</IonItemOption>
            </IonItemOptions>
        </StringJobItem>
    )
}

export default StringJobCell;
