import { useIonAlert, IonFab, IonRefresher, IonRefresherContent, IonFabButton, IonIcon, IonContent, IonHeader, IonPage, IonToolbar, IonSegment, IonSegmentButton, IonList, IonActionSheet } from '@ionic/react';
import { add } from 'ionicons/icons';
import React, { useState, useEffect, useContext } from 'react';
import RecordCard from '../components/RecordCard';
import { Log, LogObject } from '../common/types';
import { RefresherEventDetail } from '@ionic/core';
import { useUser } from '../hooks/userHooks';
import { DatabaseContext, CardsContext } from '../App';
import CreateTrainingLogModal from '../components/CreateTrainingLogModal';
import CreateMatchLogModal from '../components/CreateMatchLogModal';
import { useHistory } from 'react-router-dom';
import { styled } from '@stitches/react';

interface Feed {
  orderedArray: any[],
  cards: LogObject,
}

const Home: React.FC = () => {
  console.log('Home is running');
  const history = useHistory();

  const user = useUser();
  const userId: string = user.getUserNonAsync().userId || '';

  const database = useContext(DatabaseContext);
  const cards = useContext(CardsContext);

  const [present] = useIonAlert();

  const [segment, setSegment] = useState('training');
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showCreateCardModal, setShowCreateCardModal] = useState(false);
  const [showCreateMatchLogModal, setShowCreateMatchLogModal] = useState(false);

  const [feed, setFeed] = useState<Feed>(cards.rackets.training);

  const [focusCard, setFocusCard] = useState('');
  const [activeLog, setActiveLog] = useState<Log>({
    notes: '',
    date: '',
    startTime: '',
    endTime: '',
    time: 0,
  });

  const openActionSheet = (key: string) => {
    setShowActionSheet(true);
    setFocusCard(key);
    setActiveLog(feed.cards[key]);
  }

  const getLogs = (segment: string) => {
    if (segment == 'training') {
      setFeed(cards.rackets.training);
    } else {
      setFeed(cards.rackets.matches);
    }
  };

  const deleteLog = (segment: string, key: string) => {
    database.ref().child(userId).child(segment).child(key).remove();
    cards.refreshMatches();
    cards.refreshTraining();
    getLogs(segment);
  }

  const launchModal = (segment: string) => {
    switch (segment) {
      case 'training':
        setShowCreateCardModal(true);
        break;

      case 'matches':
        setShowCreateMatchLogModal(true);
        break;

      default:
        break;
    }
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setTimeout(() => {
      getLogs(segment);
      event.detail.complete();
    }, 500);
  }

  useEffect(() => {
    getLogs(segment);
  }, [cards])

  useEffect(() => {
    getLogs(segment);
  }, [showCreateCardModal, showCreateMatchLogModal])

  const StyledSegment = styled(IonSegment, {
    fontFamily: 'BandLab Sans',
  })

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <StyledSegment value={segment} onIonChange={(e: any) => {
            setSegment(e.detail.value);
            getLogs(e.detail.value);
          }}>
            <IonSegmentButton value="training">Training</IonSegmentButton>
            <IonSegmentButton value="matches">Matches</IonSegmentButton>
          </StyledSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent>
          </IonRefresherContent>
        </IonRefresher>
        <IonList>
          {feed ? feed.orderedArray.map((key) => <RecordCard onClick={() => openActionSheet(key)} key={key} log={feed.cards[key]} />) : ''}
        </IonList>
        <CreateTrainingLogModal activeLog={activeLog} modalStatus={showCreateCardModal} dismiss={() => {
          setActiveLog({
            notes: '',
            date: '',
            startTime: '',
            endTime: '',
            time: 0,
          });
          setShowCreateCardModal(false);
        }} />
        <CreateMatchLogModal activeLog={activeLog} modalStatus={showCreateMatchLogModal} dismiss={() => {
          setActiveLog({
            notes: '',
            date: '',
            startTime: '',
            endTime: '',
            time: 0,
          });
          setShowCreateMatchLogModal(false);
        }} />
        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => {
            console.log('closing');
            setShowActionSheet(false);
          }}
          buttons={[{
            text: 'Edit',
            handler: () => {
              launchModal(segment);
            }
          }, {
            text: 'Delete',
            role: 'destructive',
            handler: () => {
              present({
                header: 'Are you sure?',
                buttons: [
                  'Cancel',
                  {
                    text: 'Ok', handler: (d) => deleteLog(segment, focusCard)
                  },
                ],
                onDidDismiss: (e) => console.log('did dismiss'),
              });
            }
          }, {
            text: 'View History',
            handler: () => {
              console.log(activeLog.partner);
              history.push(`/partner/${activeLog.partner}`);
            }
          }, {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          }]}
        >
        </IonActionSheet>
        <IonFab
          style={{ marginBottom: '6px', marginRight: '6px' }}
          vertical="bottom"
          horizontal="end"
          slot="fixed"
        >
          <IonFabButton onClick={() => {
            setActiveLog({
              notes: '',
              date: '',
              startTime: '',
              endTime: '',
              time: 0,
            });
            setFocusCard('');
            launchModal(segment);
          }}>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default Home;
