import { styled } from '@stitches/react';
import { IonButton } from '@ionic/react';

const Form = styled('form', {
    margin: '24px',
    "& h1": {
        fontFamily: 'BandLab Sans',
        fontSize: '28px',
        marginTop: '36px',
    },
    '& label': {
        marginTop: '24px',
        fontFamily: 'BandLab Sans',
        fontWeight: '400',
        display: 'block',
    },
    '& input, textarea': {
        fontFamily: 'BandLab Sans',
        fontWeight: '300',
        margin: '12px 0px 0px 0px',
        lineHeight: '36px',
        width: '100%',
        border: '1px solid lightgray',
        borderRadius: '5px',
        padding: '0 12px',
        '&:focus': {
            outline: 'none',
        }
    },
    '& span': {
        fontFamily: 'BandLab Sans',
        fontWeight: '300',
        fontSize: '12px',
        color: 'red',
        paddingBottom: '12px',
    },
});

const StyledButton = styled(IonButton, {
    fontFamily: 'BandLab Sans',
})

export { StyledButton, Form };
