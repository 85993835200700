import { IonContent, IonRefresher, IonRefresherContent, IonPage, IonList, IonFab, IonIcon, IonFabButton, IonHeader, IonToolbar, IonSegment, IonSegmentButton } from '@ionic/react';
import RacketCell from '../components/RacketCell';
import { add } from 'ionicons/icons';
import { useEffect, useState, useRef, useContext } from 'react';
import CreateRacketModal from '../components/CreateRacketModal';
import { RefresherEventDetail } from '@ionic/core';
import { useUser } from '../hooks/userHooks';
import { useHistory } from 'react-router';
import { DatabaseContext, CardsContext } from "../App";

const Gear: React.FC = () => {
  const history = useHistory();

  const userId: string = useUser().getUserNonAsync().userId || '';

  const cards = useContext(CardsContext);
  const database = useContext(DatabaseContext);

  const [showCreateRacketModal, setShowCreateRacketModal] = useState(false);
  const [activeRacket, setActiveRacket] = useState<string>('');

  const feedList = useRef<HTMLIonListElement>(null);

  const editRacket = (key: string) => {
    console.log('setting key');
    setActiveRacket(key);
    setShowCreateRacketModal(true);
    feedList.current?.closeSlidingItems();
  }

  const deleteRacket = (uid: string) => {
    database.ref().child(userId).child('rackets').child(uid).remove();
    database.ref().child(userId).child('stringJobs').get()
      .then(snapshot => {
        snapshot.forEach(stringJob => {
          if (uid == stringJob.val().racketUid) {
            database.ref().child(userId).child('stringJobs').child(stringJob.val().uid).remove();
          };
        });
        cards.refreshRackets();
      });
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setTimeout(() => {
      cards.refreshRackets();
      event.detail.complete();
    }, 500);
  }

  useEffect(() => {
    cards.refreshRackets();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonSegment value='rackets' className="segment">
            <IonSegmentButton value="rackets">Rackets</IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent>
          </IonRefresherContent>
        </IonRefresher>
        <CreateRacketModal
          activeRacket={activeRacket}
          dismiss={() => {
            setActiveRacket('');
            cards.refreshRackets();
            setShowCreateRacketModal(false);
          }}
          modalStatus={showCreateRacketModal}
        />
        <IonList ref={feedList}>
          {cards.rackets.rackets ? cards.rackets.rackets.orderedArray.map((key) =>
            <RacketCell
              editRacket={() => editRacket(key)}
              deleteRacket={() => deleteRacket(cards.rackets.rackets.cards[key].uid)}
              key={key}
              id={key}
              racket={cards.rackets.rackets.cards[key]}
              onClick={() => {
                // setActiveRacket(key);
                history.push(`/racket/${key}`);
              }}
            />
          ) : ''}
        </IonList>
        <IonFab
          style={{ marginBottom: '6px', marginRight: '6px' }}
          vertical="bottom"
          horizontal="end"
          slot="fixed"
        >
          <IonFabButton onClick={() => setShowCreateRacketModal(true)}>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default Gear;
