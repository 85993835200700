export const useUser = () => {
    const setUser = async (userId:string, userName:string) => {
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('userName', userName);
        console.log(userId);
        console.log(userName);
        return [userId, userName];
    }

    const getUser = async () => {
        const userId = sessionStorage.getItem('userId');
        const userName = sessionStorage.getItem('userName');
        const user = {
            userId,
            userName,
        }
        return user;
    }

    const getUserNonAsync = () => {
        const userId = sessionStorage.getItem('userId');
        const userName = sessionStorage.getItem('userName');
        let user = {
            userId,
            userName,
        }
        return user;
    }

    const clearUser = async () => {
        console.log('clearing user');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userName');
        return true;
    }

    const setRackets = (total:Number) => {
        sessionStorage.setItem('totalRackets', total.toString());
        return true;
    }

    const getRackets = () => {
        const total = sessionStorage.getItem('totalRackets');
        if (total) {
            return total;
        } else {
            return '';
        }
    }

    return {
        setUser,
        getUser,
        clearUser,
        getUserNonAsync,
        setRackets,
        getRackets,
    }
}
