import { IonButtons, IonContent, IonModal, IonToolbar } from '@ionic/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ModalProps } from '../common/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatabaseContext, CardsContext } from '../App';
import { useContext, useEffect } from 'react';
import { useUser } from '../hooks/userHooks';
import { StyledButton, Form } from '../common/Modals';

const StringJobSchema = yup.object().shape({
    date: yup.date().default(() => new Date()).required(),
    tensionMains: yup.number().min(15).max(100).required(),
    stringMainsName: yup.string().required(),
    tensionCrosses: yup.number().min(15).max(100).required(),
    stringCrossesName: yup.string(),
    stringedBy: yup.string().required(),
    notes: yup.string(),
})

interface CreateStringJobModalProps extends ModalProps {
    activeLog: any,
    refreshPage?(): void,
}

const CreateStringJobModal: React.FC<CreateStringJobModalProps> = (props: CreateStringJobModalProps) => {
    const user = useUser();

    const cards = useContext(CardsContext);
    const userId: string = user.getUserNonAsync().userId || '';

    const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(StringJobSchema),
    });

    const database = useContext(DatabaseContext);

    const onSubmit = (data: any) => {
        let tempData = data;
        tempData.time = new Date(data.date).getTime();
        if (data.uid) {
            console.log('existing ref');
        } else {
            console.log('creating new one');
            const newPostRef = database.ref(userId).child('stringJobs').push();
            tempData.uid = newPostRef.key;
            newPostRef.set(tempData, error => {
                console.log(error);
            })
            .then(() => {
                reset();
                cards.refreshRackets();
                cards.refreshStringJobs();
                if (props.refreshPage) {
                    props.refreshPage();
                } else {
                    console.log('no rfresh');
                }
                props.dismiss();
            });
        }
    }

    useEffect(() => {
        console.log(props.activeLog);
        setValue('racketUid', props.activeLog.uid);
    }, [props.activeLog]);

    useEffect(() => {
        console.log(errors);
    }, [errors])

    return (
        <IonModal swipeToClose={false} showBackdrop={true} isOpen={props.modalStatus} onDidDismiss={() => { reset(); props.dismiss(); }}>
            <IonContent>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <h1>Add/Edit String Job</h1>
                    <label>Stringing Date</label>
                    <input type="date" {...register("date")} />
                    {errors.date && <span>Date can't be empty</span>}
                    <label>Tension (Mains)</label>
                    <input type="number" {...register("tensionMains")} />
                    {errors.tensionMains && <span>Tension can't be empty, must be between 15-100</span>}
                    <label>String (Mains)</label>
                    <input type="text" {...register("stringMainsName")} />
                    {errors.stringMainsName && <span>Mains string can't be empty</span>}
                    <label>Tension (Crosses)</label>
                    <input type="number" {...register("tensionCrosses")} />
                    {errors.tensionCrosses && <span>Tension can't be empty, must be between 15-100</span>}
                    <label>String (Crosses)</label>
                    <input type="text" {...register("stringCrossesName")} />
                    {errors.stringCrossesName && <span>Crosses string can't be empty</span>}
                    <label>Stringed By</label>
                    <input type="text" {...register("stringedBy")} />
                    {errors.stringedBy && <span>Stringer can't be empty</span>}
                    <label>Notes</label>
                    <textarea {...register("notes")} rows={3} />
                </Form>
            </IonContent>
            <IonToolbar>
                <IonButtons slot="end">
                    <StyledButton onClick={() => {
                        reset();
                        props.dismiss();
                    }}>
                        Cancel
                    </StyledButton>
                </IonButtons>
                <IonButtons slot="end">
                    <StyledButton onClick={() => {
                        handleSubmit(onSubmit)();
                    }}>
                        Done
                    </StyledButton>
                </IonButtons>
            </IonToolbar>
        </IonModal>
    )
}

export default CreateStringJobModal;
