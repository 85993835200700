import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { IonReactRouter } from '@ionic/react-router';
import { homeOutline, hammerOutline, informationCircleOutline, timeOutline, logIn } from 'ionicons/icons';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import Home from './pages/Home';
import About from './pages/About';
import Gear from './pages/Gear';
import Login from './pages/Login';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Global css + fonts */
import './theme/globals.scss';
import './theme/fonts.css';

/* User in session */
import { useUser } from './hooks/userHooks';
import Racket from './pages/rackets/Racket';
import History from './pages/History';
import Partner from './pages/partners/Partner';
import { LogObject, RacketObject, StringJobObject } from './common/types';

let firebaseConfig = require('./firebaseConfig');

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
let database = firebase.database();
let auth = firebase.auth();

const App: React.FC = () => {
  let init = false;
  if (sessionStorage.getItem('userId')) {
    init = true;
  }

  const user = useUser();
  const [authStatus, setAuthStatus] = useState(init);

  // Contexts
  const [rackets, setRackets] = useState<{
    orderedArray: any[],
    cards: RacketObject,
  }>({
    orderedArray: [],
    cards: {},
  });

  const [stringJobs, setStringJobs] = useState<{
    orderedArray: any[],
    cards: StringJobObject,
  }>({
    orderedArray: [],
    cards: {},
  });

  const [matches, setMatches] = useState<{
    orderedArray: any[],
    cards: LogObject,
  }>({
    orderedArray: [],
    cards: {},
  });

  const [training, setTraining] = useState<{
    orderedArray: any[],
    cards: LogObject,
  }>({
    orderedArray: [],
    cards: {},
  });

  const refreshRackets = (userId: string) => {
    database.ref().child(userId).child('rackets').get()
      .then(snapshot => {
        let tempArray: any[] = [];
        snapshot.forEach(racket => {
          tempArray.unshift(racket.key);
        });
        setRackets({
          orderedArray: tempArray,
          cards: snapshot.val(),
        })
      });
  }

  const refreshStringJobs = (userId: string) => {
    database.ref().child(userId).child('stringJobs').get()
      .then(snapshot => {
        let tempArray: any[] = [];
        snapshot.forEach(stringJob => {
          tempArray.unshift(stringJob.key);
        });
        setStringJobs({
          orderedArray: tempArray,
          cards: snapshot.val(),
        })
      });
  }

  const refreshMatches = (userId: string) => {
    database.ref().child(userId).child('matches').orderByChild('time').get()
      .then(snapshot => {
        let tempArray: any[] = [];
        snapshot.forEach(match => {
          tempArray.unshift(match.key);
        });
        setMatches({
          orderedArray: tempArray,
          cards: snapshot.val(),
        })
      });
  }

  const refreshTraining = (userId: string) => {
    database.ref().child(userId).child('training').orderByChild('time').get()
      .then(snapshot => {
        let tempArray: any[] = [];
        snapshot.forEach(training => {
          tempArray.unshift(training.key);
        });
        setTraining({
          orderedArray: tempArray,
          cards: snapshot.val(),
        })
      });
  }

  useEffect(() => {
    const userId = user.getUserNonAsync().userId || '';
    if (userId) {
      refreshRackets(userId);
      refreshStringJobs(userId);
      refreshMatches(userId);
      refreshTraining(userId);
    }
  }, [authStatus]);

  return (
    <div>
      <IonApp>
        <AuthContext.Provider value={{
          auth,
          isAuthenticated: false,
          login: () => { setAuthStatus(true); },
          logout: () => {
            user.clearUser()
              .then(() => {
                setAuthStatus(false);
              })
          },
        }}>
          {authStatus ?
            <DatabaseContext.Provider value={database}>
              <CardsContext.Provider value={{
                rackets: {
                  rackets,
                  stringJobs,
                  matches,
                  training,
                },
                refreshRackets: () => refreshRackets(user.getUserNonAsync().userId || ''),
                refreshStringJobs: () => refreshStringJobs(user.getUserNonAsync().userId || ''),
                refreshMatches: () => refreshMatches(user.getUserNonAsync().userId || ''),
                refreshTraining: () => refreshTraining(user.getUserNonAsync().userId || '')
              }}>
                <IonReactRouter>
                  <IonTabs>
                    <IonRouterOutlet>
                      <Route exact path="/home">
                        <Home />
                      </Route>
                      <Route exact path="/">
                        <Redirect to="/home" />
                      </Route>
                      <Route path="/about">
                        <About />
                      </Route>
                      <Route path="/gear">
                        <Gear />
                      </Route>
                      <Route path="/history" component={History} />
                      <Route path="/racket/:id" component={Racket} />
                      <Route path="/partner/:id" component={Partner} />
                    </IonRouterOutlet>
                    <IonTabBar slot="bottom">
                      <IonTabButton tab="home" href="/home">
                        <IonIcon icon={homeOutline} />
                        <IonLabel>Home</IonLabel>
                      </IonTabButton>
                      <IonTabButton tab="gear" href="/gear">
                        <IonIcon icon={hammerOutline} />
                        <IonLabel>Gear</IonLabel>
                      </IonTabButton>
                      <IonTabButton tab="history" href="/history">
                        <IonIcon icon={timeOutline} />
                        <IonLabel>History</IonLabel>
                      </IonTabButton>
                      <IonTabButton tab="about" href="/about">
                        <IonIcon icon={informationCircleOutline} />
                        <IonLabel>About</IonLabel>
                      </IonTabButton>
                    </IonTabBar>
                  </IonTabs>
                </IonReactRouter>
              </CardsContext.Provider>
            </DatabaseContext.Provider>
            : <Login />}
        </AuthContext.Provider >
      </IonApp>
    </div >
  )
};

export default App;

const DatabaseContext = React.createContext(database);
const AuthContext = React.createContext({
  auth,
  isAuthenticated: false,
  login: () => { },
  logout: () => { },
});
const CardsContext = React.createContext<{
  rackets: {
    rackets: {
      orderedArray: any[],
      cards: RacketObject,
    },
    stringJobs: {
      orderedArray: any[],
      cards: StringJobObject,
    },
    matches: {
      orderedArray: any[],
      cards: LogObject,
    },
    training: {
      orderedArray: any[],
      cards: LogObject,
    }
  },
  refreshRackets(): void,
  refreshStringJobs(): void,
  refreshMatches(): void,
  refreshTraining(): void,
}>({
  rackets: {
    rackets: {
      orderedArray: [],
      cards: {}
    },
    stringJobs: {
      orderedArray: [],
      cards: {}
    },
    matches: {
      orderedArray: [],
      cards: {}
    },
    training: {
      orderedArray: [],
      cards: {}
    }
  },
  refreshRackets: () => { },
  refreshStringJobs: () => { },
  refreshMatches: () => { },
  refreshTraining: () => { },
});

export { DatabaseContext, AuthContext, CardsContext };
