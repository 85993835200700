import { IonItem, IonLabel, useIonAlert, IonItemSliding, IonItemOptions, IonItemOption } from '@ionic/react';
import React, { useRef } from 'react';
import { styled } from '@stitches/react';
import { Racket } from '../common/types';

interface RacketCellProps {
    onClick(): void;
    deleteRacket(): void;
    editRacket(racket: Racket): void;
    racket: Racket;
    id: number;
}

const RacketItem = styled(IonItemSliding, {
    cursor: 'pointer',

    '& ion-item-option': {
        fontFamily: 'BandLab Sans',
        minWidth: '150px',
    },
    '& h4': {
        fontFamily: 'BandLab Sans',
        textTransform: 'uppercase',
        fontSize: '12px',
        letterSpacing: '1px',
    },
    '& .title': {
        fontFamily: 'BandLab Sans',
        marginTop: '4px',
        color: 'black !important',
    },
    '& .subtitle': {
        fontFamily: 'BandLab Sans',
        fontSize: '12px',
        textTransform: 'uppercase',
    },
});

const TrainingNotes = styled('p', {
    marginBottom: '4px !important',
})

const RacketCell: React.FC<RacketCellProps> = (props: RacketCellProps) => {
    const [present] = useIonAlert();
    const ionItem = useRef<HTMLIonItemSlidingElement>(null);

    return (
        <RacketItem ref={ionItem}>
            <IonItemOptions side="start">
                <IonItemOption onClick={() => props.editRacket(props.racket)}>Edit</IonItemOption>
            </IonItemOptions>
            <IonItem onClick={() => props.onClick()}>
                <IonLabel>
                    <h4>{props.racket.brand}</h4>
                    <p className="ion-text-wrap title">{props.racket.name}{props.racket.number && ` (#${props.racket.number})`}</p>
                    {props.racket.notes ? <TrainingNotes className="ion-text-wrap title" style={{ fontWeight: 300 }}>{props.racket.notes} </TrainingNotes> : ''}
                    <p className="subtitle">W: {props.racket.weight ? `${props.racket.weight}g` : 'N/A'} B: {props.racket.balance ? `${props.racket.balance}mm` : 'N/A'} SW: {props.racket.sw ? `${props.racket.sw}g` : 'N/A'}</p>
                </IonLabel>
            </IonItem>
            <IonItemOptions side="end">
                <IonItemOption color="danger" onClick={() => {
                    ionItem.current?.close()
                        .then(() => {
                            present({
                                header: 'Are you sure?',
                                message: 'Deleting this racket will also delete any associated string jobs.',
                                buttons: [
                                    'Cancel',
                                    { text: 'Ok', handler: (d) => props.deleteRacket() },
                                ],
                                onDidDismiss: (e) => console.log('did dismiss'),
                            });
                        });
                }}>
                    Delete
                </IonItemOption>
            </IonItemOptions>
        </RacketItem>
    )
}

export default RacketCell;
