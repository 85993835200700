import { useContext } from 'react';
import { AuthContext } from '../App';
import { useForm } from 'react-hook-form';
import { IonButton } from '@ionic/react';
import { useUser } from '../hooks/userHooks';
import { styled } from '@stitches/react';
import { text } from 'ionicons/icons';

const Login = () => {
    const { login, auth } = useContext(AuthContext);
    const { handleSubmit, register } = useForm();
    const user = useUser();

    const onSubmit = (data: any) => {
        if (data.email && data.password) {
            auth.signInWithEmailAndPassword(data.email, data.password)
                .then(userCredential => {
                    if (typeof userCredential.user?.uid === 'string' && typeof userCredential.user?.email === 'string') {
                        user.setUser(userCredential.user.uid, userCredential.user.email);
                        login();
                    } else {
                        console.log('no uid');
                    }
                })
                .catch((error) => {
                    alert(error.message);
                });
        }
    }

    const LoginButton = styled('button', {
        padding: '12px 36px',
        borderRadius: '5px',
        backgroundColor: '#3880ff',
        color: 'white',
        textTransform: 'uppercase',
        '&:hover': {
            opacity: '0.9',
        }
    });

    const LoginBox = styled('div', {
        display: 'flex',
        height: '100vh',
        fontFamily: '$blSans'
    });

    const LoginForm = styled('div', {
        margin: 'auto',
        textAlign: 'center',
        width: '240px',
        maxWidth: '80%',
        '& h1': {
            margin: '24px 0px',
        },
        '& input': {
            textAlign: 'center',
            margin: '12px 0px 0px 0px',
            lineHeight: '36px',
            width: '100%',
            border: '1px solid lightgray',
            borderRadius: '5px',
            padding: '0 12px',
            '&:focus': {
                outline: 'none',
            }
        },
        '& ion-button': {
            fontFamily: '$blSans',
        }
    });

    const EarlyAccessSection = styled('p', {
        fontSize: '12px',
        marginTop: '24px',
        cursor: 'pointer',
        '& a': {
            color: 'black',
        }
    })

    return (
        <LoginBox>
            <LoginForm>
                <h1>Tennis Buddy</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label>Email</label><br />
                    <input {...register('email')} type="email" required /><br /><br />
                    <label>Password</label><br />
                    <input {...register('password')} type="password" required /><br /><br />
                    {/* <IonButton type="submit">Sign In</IonButton> */}
                    <LoginButton type="submit">Sign In</LoginButton>
                </form>
                <EarlyAccessSection><a href="mailto:meng@bandlab.com?subject=Requesting%20Access%20to%20Tennis%20Logger">Request to join early access list</a></EarlyAccessSection>
            </LoginForm>
        </LoginBox>
    )
}

export default Login;

