import { IonButtons, IonContent, IonModal, IonToolbar } from '@ionic/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ModalProps, Racket } from '../common/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatabaseContext, CardsContext } from '../App';
import { useContext, useEffect } from 'react';
import { useUser } from '../hooks/userHooks';
import { StyledButton, Form } from '../common/Modals';

const RacketSchema = yup.object().shape({
    brand: yup.string().required(),
    name: yup.string().required(),
    weight: yup.number(),
    balance: yup.number(),
    sw: yup.number(),
    number: yup.number().integer().min(1).max(12),
    notes: yup.string(),
})

interface CreateRacketModalProps extends ModalProps {
    activeRacket: string,
}

const CreateRacketModal: React.FC<CreateRacketModalProps> = (props: CreateRacketModalProps) => {
    const user = useUser();
    const userId: string = user.getUserNonAsync().userId || '';

    const { register, reset, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(RacketSchema),
    });

    const database = useContext(DatabaseContext);
    const cards = useContext(CardsContext);

    const onSubmit = (data: any) => {
        console.log(data);
        props.dismiss();
        let tempData = data;
        if (data.uid) {
            database.ref(userId).child('rackets').child(data.uid).set(tempData)
                .then(() => {
                    reset();
                    props.dismiss();
                });
        } else {
            console.log('creating new one');
            const newPostRef = database.ref(userId).child('rackets').push();
            tempData.uid = newPostRef.key;
            newPostRef.set(tempData, error => {
                console.log(error);
            })
                .then(() => {
                    reset();
                    props.dismiss();
                });
        }
    }

    useEffect(() => {
        console.log('activelog set');
        console.log(props.activeRacket);
        if (cards.rackets.rackets.cards) {
            setValue('uid', cards.rackets.rackets.cards[props.activeRacket]?.uid);
            setValue('brand', cards.rackets.rackets.cards[props.activeRacket]?.brand);
            setValue('name', cards.rackets.rackets.cards[props.activeRacket]?.name);
            setValue('number', cards.rackets.rackets.cards[props.activeRacket]?.number);
            setValue('notes', cards.rackets.rackets.cards[props.activeRacket]?.notes);
        } else {
            setValue('uid', '');
            setValue('brand', '');
            setValue('name', '');
            setValue('number', 1);
            setValue('notes', '');
        }
    }, [props.activeRacket]);

    useEffect(() => {
        console.log(errors);
    }, [errors])

    return (
        <IonModal swipeToClose={false} showBackdrop={true} isOpen={props.modalStatus} onDidDismiss={() => { reset(); props.dismiss(); }}>
            <IonContent>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <h1>Add/Edit Racket</h1>
                    <label>Brand</label>
                    <input type="text" {...register("brand")} />
                    {errors.brand && <span>Brand can't be empty</span>}
                    <label>Name</label>
                    <input type="text" {...register("name")} />
                    {errors.name && <span>Name can't be empty</span>}
                    <label>Number (for multiple rackets)</label>
                    <input type="number" {...register("number")} value={1} />
                    {errors.number && <span>Must be an integer between 1-12</span>}
                    <label>Notes</label>
                    <textarea {...register("notes")} rows={3} />
                </Form>
            </IonContent>
            <IonToolbar>
                <IonButtons slot="end">
                    <StyledButton onClick={() => {
                        reset();
                        props.dismiss();
                    }}>
                        Cancel
                    </StyledButton>
                </IonButtons>
                <IonButtons slot="end">
                    <StyledButton onClick={() => {
                        handleSubmit(onSubmit)();
                    }}>
                        Done
                    </StyledButton>
                </IonButtons>
            </IonToolbar>
        </IonModal>
    )
}

export default CreateRacketModal;
