import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useState, useContext } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import moment from 'moment';
import { DatabaseContext } from "../../App";
import { useUser } from "../../hooks/userHooks";
import { styled } from '@stitches/react';

interface PartnerComponentProps extends RouteComponentProps<{
    id: string;
}> { }

const Partner: React.FC<PartnerComponentProps> = (props: PartnerComponentProps) => {
    const history = useHistory();

    const user = useUser();
    const userId = user.getUserNonAsync().userId || '';

    const database = useContext(DatabaseContext);

    const [training, setTraining] = useState<Number>(0);
    const [trainingMinutes, setTrainingMinutes] = useState<Number>(0);
    const [matchArray, setMatchArray] = useState<any[]>([]);
    const [headToHead, setHeadToHead] = useState<{
        win: number,
        draw: number,
        loss: number,
        total: number,
        matchTime: number,
    }>({
        win: 0,
        draw: 0,
        loss: 0,
        total: 0,
        matchTime: 0,
    })

    const getMatches = (partner: string) => {
        database.ref().child(userId).child('matches').orderByChild('time').get()
            .then(snapshot => {
                let win = 0;
                let draw = 0;
                let loss = 0;
                let total = 0;
                let matchTime = 0;
                let tempArray: any[] = [];
                snapshot.forEach(match => {
                    if (match.val().partner === partner) {
                        tempArray.unshift(match.val());
                        total++;
                        matchTime = matchTime + moment(match.val().endTime, 'HH:mm').diff(moment(match.val().startTime, 'HH:mm'), 'minutes');
                        switch (match.val().result) {
                            case 'win':
                                win++
                                break;
                            case 'draw':
                                draw++
                                break;
                            case 'loss':
                                loss++;
                                break;
                            default:
                                break;
                        }
                    }
                });
                console.log(tempArray);
                setMatchArray(tempArray);
                setHeadToHead({
                    win,
                    draw,
                    loss,
                    total,
                    matchTime,
                });
            });
    }

    const getTraining = (uid: string) => {
        console.log(uid);
        database.ref().child(userId).child('training').get()
            .then(snapshot => {
                let tempMinutes = 0;
                let trainingNumber = 0;
                snapshot.forEach(data => {
                    if (data.val().partner == uid) {
                        console.log('match');
                        trainingNumber++;
                        tempMinutes = tempMinutes + moment(data.val().endTime, 'HH:mm').diff(moment(data.val().startTime, 'HH:mm'), 'minutes');
                    }
                });
                setTrainingMinutes(tempMinutes);
                setTraining(trainingNumber);
            });
    }

    useEffect(() => {
        getMatches(props.match.params.id);
        getTraining(props.match.params.id);
    }, [props.match.params.id]);

    const PartnerContent = styled(IonContent, {
        fontFamily: 'BandLab Sans',
        '& .details': {
            margin: '24px',
            '& h1': {
                fontSize: '28px',
            },
            '& h3': {
                fontSize: '18px',
            },
            '& p': {
                fontWeight: 300,
                lineHeight: '24px',
            }
        }
    })

    const HeadToHeadContainer = styled('div', {
        maxWidth: '700px',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        '& div': {
            padding: '0 12px',
            textAlign: 'center',
            flexGrow: 1,
            flex: 1,
        },
        '& h1': {
            fontSize: '24px !important',
            margin: '0',
        },
    })

    const ResultBox = styled('span', {
        display: 'inline-block',
        color: 'white',
        textTransform: 'uppercase',
        padding: '4px 6px',
        marginRight: '6px',
        borderRadius: '6px',
        width: '60px',
        textAlign: 'center',
        fontSize: '12px',
        variants: {
            result: {
                win: {
                    backgroundColor: '#4CBB17',
                },
                draw: {
                    backgroundColor: 'grey',
                },
                loss: {
                    backgroundColor: 'red',
                },
            },
        },
    });

    return (
        <IonPage>
            <PartnerContent>
                <div className="details">
                    <a style={{ cursor: 'pointer', 'color': 'black' }} onClick={() => history.goBack()}>&larr;&nbsp;Back</a>
                    <br /><br /><br />
                    <HeadToHeadContainer>
                        <div>
                            <h1>You</h1>
                        </div>
                        <div>
                            <h1>vs.</h1>
                        </div>
                        <div>
                            <h1>{props.match.params.id}</h1>
                        </div>
                    </HeadToHeadContainer>
                    <HeadToHeadContainer>
                        <div>
                            <h3>{headToHead.win ? headToHead.win : '0'}</h3>
                            <h3>{headToHead.win ? ((headToHead.win / headToHead.total) * 100).toFixed(2) + '%' : '0%'}</h3>
                        </div>
                        <div>
                            <h3>Wins</h3>
                            <h3>%</h3>
                        </div>
                        <div>
                            <h3>{headToHead.win ? headToHead.total - headToHead.win - headToHead.draw : '0'}</h3>
                            <h3>{headToHead.win ? (((headToHead.total - headToHead.win - headToHead.draw) / headToHead.total) * 100).toFixed(2) + '%' : '0%'}</h3>
                        </div>
                    </HeadToHeadContainer><br />
                    <h3>Partner Details:</h3>
                    <p>Win: {headToHead.win ? headToHead.win : '0'} {headToHead.win ? '(' + ((headToHead.win / headToHead.total) * 100).toFixed(2) + '%)' : ''}
                        <br />Draw: {headToHead.draw ? headToHead.draw : '0'} {headToHead.draw ? '(' + ((headToHead.draw / headToHead.total) * 100).toFixed(2) + '%)' : ''}
                        <br />Loss: {headToHead.loss ? headToHead.loss : '0'} {headToHead.loss ? '(' + ((headToHead.loss / headToHead.total) * 100).toFixed(2) + '%)' : ''}
                        <br />Total Matches: {headToHead.total ? headToHead.total : '0'}
                        <br />Total Match Minutes Played: {headToHead.matchTime ? headToHead.matchTime : '0'}
                        <br />Total Training Sessions: {training ? training : '0'}
                        <br />Total Training Minutes Played: {trainingMinutes ? trainingMinutes : '0'}</p>
                    {matchArray.length > 0 ?
                        <>
                            <h3>Match History:</h3>
                            <p>
                                {matchArray.map(match => <span style={{ margin: '4px 0px', display: 'block' }} key={match.uid}><ResultBox result={match.result}>{match.result}</ResultBox>&nbsp;{moment(match.date).format('DD/MM/YYYY')}: {match.notes}<br /></span>)}
                            </p>
                        </> : ''}
                </div>
            </PartnerContent>
        </IonPage>
    )
}

export default Partner;
