import { RefresherEventDetail } from "@ionic/core";
import { IonPage, IonRefresher, IonRefresherContent, IonHeader, IonToolbar, IonSegment, IonSegmentButton, IonContent, IonList, IonFabButton, IonIcon, IonFab } from "@ionic/react";
import { useContext, useEffect, useRef } from "react";
import { DatabaseContext, CardsContext } from "../App";
import StringJobCell from "../components/StringJobCell";
import { useUser } from "../hooks/userHooks";
// import { HistoryGearFeed } from "../common/types";

const History: React.FC = () => {
    console.log('history is running');
    const user = useUser();
    const userId = user.getUserNonAsync().userId || '';

    const cards = useContext(CardsContext);
    const database = useContext(DatabaseContext);
    const list = useRef<HTMLIonListElement>(null);

    const deleteStringJob = (uid: string) => {
        database.ref().child(userId).child('stringJobs').child(uid).remove();
        cards.refreshStringJobs();
        list.current?.closeSlidingItems();
    }

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setTimeout(() => {
            cards.refreshStringJobs();
            event.detail.complete();
        }, 500);
    }

    useEffect(() => {
        console.log('refreshing stringJObs');
        cards.refreshStringJobs();
    }, [cards.rackets.rackets]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonSegment value='stringJobs' className="segment">
                        <IonSegmentButton value="stringJobs">String Jobs</IonSegmentButton>
                    </IonSegment>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent>
                    </IonRefresherContent>
                </IonRefresher>
                <IonList ref={list}>
                    {cards.rackets.stringJobs.orderedArray.length > 0 ?
                        cards.rackets.stringJobs.orderedArray.map(key =>
                            <StringJobCell
                                key={key}
                                id={key}
                                stringJob={cards.rackets.stringJobs.cards[key]}
                                // racket={cards.rackets.rackets.cards[cards.rackets.stringJobs.cards[key].racketUid]}
                                editStringJob={() => { }}
                                deleteStringJob={() => deleteStringJob(key)}
                            />) : ''}
                </IonList>
            </IonContent>
        </IonPage>
    )
}

export default History;
