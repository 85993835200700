import { IonContent, IonPage } from "@ionic/react"
import { useEffect, useContext, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router";
import { CardsContext } from "../../App";
import { Racket as RacketType } from "../../common/types";
import { styled } from '@stitches/react';
import CreateStringJobModal from "../../components/CreateStringJobModal";
import { format } from 'date-fns';

interface RacketComponentProps extends RouteComponentProps<{
    id: string;
}> {
}

const Racket: React.FC<RacketComponentProps> = (props: RacketComponentProps) => {
    const history = useHistory();

    const cards = useContext(CardsContext);

    const defaultRacket: RacketType = {
        brand: '',
        name: '',
        weight: 0,
        balance: 0,
        sw: 0,
        uid: '',
        image: '',
        notes: '',
    }

    const [racket, setRacket] = useState<RacketType>(defaultRacket);
    const [showStringJobModal, setShowStringJobModal] = useState(false);
    const [lastStrung, setLastStrung] = useState(0);
    const [stringJobs, setStringJobs] = useState<any[]>([]);

    const getLastStrung = (key: string) => {
        let tempLastStrung = 0;
        let tempArray: any[] = [];
        cards.rackets.stringJobs.orderedArray.forEach(stringJob => {
            if (cards.rackets.stringJobs.cards[stringJob].racketUid == key) {
                tempLastStrung = cards.rackets.stringJobs.cards[stringJob].time;
                tempArray.unshift(cards.rackets.stringJobs.cards[stringJob]);
            };
        });
        setLastStrung(tempLastStrung);
        setStringJobs(tempArray);
    };

    useEffect(() => {
        if (cards.rackets.rackets.cards) {
            setRacket(cards.rackets.rackets.cards[props.match.params.id]);
        };
        getLastStrung(props.match.params.id);
    }, [props.match.params.id, cards]);

    const RacketContent = styled(IonContent, {
        fontFamily: 'BandLab Sans',
        '& .details': {
            display: 'flex',
            flexFlow: 'row wrap',
            margin: '24px',
            '& h1': {
                fontSize: '28px',
            },
            '& h3': {
                fontSize: '18px',
            },
            '& p': {
                fontWeight: 300,
                lineHeight: '24px',
            }
        }
    })

    const RacketButton = styled('button', {
        padding: '12px 36px',
        margin: '12px',
        borderRadius: '5px',
        backgroundColor: '#3880ff',
        color: 'white',
        textTransform: 'uppercase',
        '&:hover': {
            opacity: '0.9',
        }
    });

    return (
        <IonPage>
            <RacketContent>
                <div className="details">
                    <div style={{ display: "flex", flexFlow: "row wrap", justifyContent: "space-between", flexBasis: '100%' }}>
                        <div><a style={{ cursor: 'pointer', 'color': 'black' }} onClick={() => history.goBack()}>&larr;&nbsp;Back</a></div>
                        {/* <div>
                            <a style={{ cursor: 'pointer', 'color': 'black', marginRight: '24px' }}>Edit</a>
                            <a style={{ cursor: 'pointer', 'color': 'black' }}>Delete</a>
                        </div> */}
                    </div>
                    <br /><br /><br />
                    {racket ?
                        <>
                            <div style={{ flex: '0 0 100%', textAlign: 'center' }}>
                                <h1>
                                    {racket.brand ? racket.brand : ''}&nbsp;{racket.name ? `${racket.name}` : ''}
                                </h1>
                                <span>Last Strung: {lastStrung ? format(new Date(lastStrung), 'dd/MM/yy') : 'N/A'}</span>
                            </div>
                            <div style={{ display: 'flex', flex: '0 0 100%', justifyContent: 'center' }}>
                                <RacketButton onClick={() => setShowStringJobModal(true)}>Add String Job</RacketButton>
                            </div>
                            <CreateStringJobModal
                                modalStatus={showStringJobModal}
                                activeLog={racket}
                                dismiss={() => setShowStringJobModal(false)}
                            />
                            <div style={{ display: 'flex', flex: '0 0 100%', justifyContent: 'center', flexFlow: 'row wrap', textAlign: 'center' }}>
                                {racket ?
                                    <div style={{ display: 'flex', padding: '12px', flex: '0 0 100%', justifyContent: 'center' }}>
                                        <div style={{ width: '300px' }}>
                                            <span className="subtitle">
                                                Specs
                                            </span>
                                            <p style={{ marginBottom: '0' }}>
                                                Static Weight: {racket.weight ? racket.weight + 'g' : 'N/A'}<br />
                                                Balance: {racket.balance ? racket.balance + 'mm' : 'N/A'}<br />
                                                Swing Weight: {racket.sw ? racket.sw + 'g' : 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                    : ''}
                                <div style={{ padding: '12px', width: '250px' }}>
                                    <span className="subtitle">
                                        Notes
                                    </span>
                                    <p>
                                        {racket.notes ? racket.notes : 'N/A'}
                                    </p>
                                    <span className="subtitle">
                                        String Job History
                                    </span>
                                    <p>
                                        {stringJobs.length > 0 ? stringJobs.map(stringJob => <span key={stringJob.uid}>{format(stringJob.time, 'dd/MM/yy')} by {stringJob.stringedBy}<br /></span>) : 'N/A'}
                                    </p>
                                </div>
                            </div>
                        </>
                        : 'Loading...'}
                </div>
            </RacketContent>
        </IonPage>
    )
}

export default Racket;
